import React from "react"

import Layout from "../components/layout"
import Logo from "../components/logo"
import SEO from "../components/seo"
import "./index.css"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy" />

    <div className={"page"}>
      <div style={{ marginBottom: 30 }}>
        <Logo width={200} />
      </div>
      <div>
        <h2>Privacy Policy</h2>
        <h3>Effective date: 07.14.2020</h3>
        <div>
          At SafeguardPass, we take your privacy seriously. Please read this
          Privacy Policy to learn how SafeguardPass (“SafeguardPass,” “we,”
          “us,” or “our”) operates with respect to the collection and use of
          personal information, including through our website, and mobile
          website, (collectively, “the Website”) and our diagnostic testing
          device, mobile applications, or any SafeguardPass devices,
          applications, services, or websites linked or otherwise connected
          thereto (“Testing Platform”). We do not collect any personal
          information through our Testing Platform, and collect only limited
          information from your use of our Website, as described below. This
          Privacy Policy does not cover the collection and use of your
          information by third parties who may use SafeguardPass’s Testing
          Platform. Please review those third parties’ privacy policies and
          practices for further information.
        </div>

        <div>
          By using or accessing our Website and Testing Platform, you
          acknowledge that you accept the practices and policies outlined below,
          along with our Terms of Use, which is incorporated by reference in its
          entirety, where and as permitted by applicable law. Any terms we use
          in this Policy without defining them have the definitions given to
          them in the Terms of Use.
        </div>
        <h3>What Information Do We Collect and How Do We Use It? </h3>
        <div>
          We do not collect any personal information other than as described
          below. In particular, we do not collect any personal information
          through the use of our Testing Platform. We do collect de-identified
          test results (a “positive” or “negative” result), the test number
          (which is not tied to any individual) for public health, internal, and
          or research purposes.
        </div>
        <h3>Information Automatically Collected on our Website </h3>
        <div>
          If you are interested in job openings at SafeguardPass, you may elect
          to sign up for updates on our Website by providing us your email
          address.
        </div>
        <div>
          When you visit our Website, we automatically collect your IP address,
          browser, and device characteristics, operating system, language
          preferences, referring URLs, device name, country, location,
          information about how and when you use the Website and other technical
          information. This information does not reveal your individual
          identity, and we only use it to operate and maintain the security of
          the Website, and for our internal analytics and reporting purposes.
        </div>
        <h3>How Do We Share Information? </h3>
        <div>
          We do not share the information described in this policy with anyone,
          except to comply with the law, develop our Website, and protect our
          rights.
        </div>
        <h3>Third Party Vendors </h3>
        <div>
          We may have contractual agreements with affiliates, services
          providers, partners, and other third parties who may use the
          information described in this policy to operate our Website, and to
          develop, market, and provide our products. Any information collected
          or used by third party vendors is limited to the collection described
          in this policy, and may be used only for the purposes described.
        </div>
        <h3>Legal Process, Security, Defense, Protection </h3>
        <div>
          Occasionally, we may be required to share information with third
          parties for legitimate business purposes, or to comply with legal
          obligations. For example, we may share information when we believe in
          good faith that an applicable law requires it; at the request of law
          enforcement, judicial authorities (e.g. upon receipt of a court order
          or subpoena), or appropriate governmental authorities; to detect and
          protect against fraud, or any technical or security vulnerabilities;
          to respond to an emergency; or otherwise to protect the rights,
          property, safety or security of third parties, visitors to our
          Website, our businesses, or the public.
        </div>
        <h3>Change in Control or Sale </h3>
        <div>
          We may choose to buy or sell assets, and may share and or transfer
          customer information in connection with the evaluation of and entry
          into such transactions. Also, if we (or our assets) are acquired, or
          if we go out of business, enter bankruptcy, or go through some other
          change of control, Personal Information could be one of the assets
          transferred to or acquired by a third party. However, we note that any
          entity acquiring our business or assets would have an obligation to
          use the Personal Information that comes with it strictly in accordance
          with this Privacy Policy. You acknowledge that such transfers may
          occur, and that any acquirer of us or our assets may continue to use
          your Personal Information only as set forth in this policy, unless you
          are informed otherwise.
        </div>
        <h3>Changes to the Privacy Policy </h3>
        <div>
          We reserve the right to revise this Privacy Policy at any time by
          amending this page and changes will be effective upon being posted
          unless we advise otherwise. If we make any material changes to this
          Privacy Policy we will notify you by means of a notice on our Website
          prior to the change becoming effective. If you do not accept the terms
          of this Privacy Policy, we ask that you discontinue use of our Website
          and Testing Platform.
        </div>
        <h3>Contact </h3>
        <div>
          If you have questions on the processing of your personal information,
          or have a complaint, please contact us here:
          privacy@safeguardpass.com.
        </div>
      </div>
    </div>
  </Layout>
)

export default PrivacyPage
